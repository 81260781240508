input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}

input[type='number'] {
  -moz-appearance: textfield;
}
