
::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
}
::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 8px;
    background-clip: content-box;
    border: 2px solid transparent;
}
body[scroll]::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb:hover {
    background-color: #a5a9b5;
}


